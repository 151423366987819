<script lang="ts" setup>
const document = ref();
usePageNavigation(document, "document");
</script>

<template>
  <ScrollWrapper>
    <Navigation />
    <section
      id="document"
      ref="document"
      :class="
        cn(
          'max-lg:w-[100vw] max-xl:shrink-0 lg:max-xl:w-[66.666vw] xl:basis-0 xl:grow h-full flex flex-col border-r border-strong',
          'scroll-area max-xl:snap-start',
          'px-5'
        )
      "
    >
      <NuxtPage />
    </section>
    <!-- <ChatAi /> -->
  </ScrollWrapper>
</template>
